import React from 'react';
import styled from 'styled-components';
import { themeGet } from '../../utils';

import Inner from '../../components/templates/Inner';
import { Box, Flex, Text, Title, Divider, Link } from '../../components/atoms';

import { principles as nav } from '../../nav';

import avatarDima from '../../assets/img/principles/dima.jpg';
import easing from '../../assets/img/principles/easing-2.png';

const ColorBox = styled(Box)`
  border: 1px solid ${themeGet('colors.grayscale.400')};
`;

const Principles = () => (
  <Inner nav={nav} title="Principles">
    <Title as="h1" mb={64}>
      Heartbeat design principles
    </Title>
    <Divider mt={72} mb={64} />
    <Title size="h4" mb="m">
      In general
    </Title>
    <Text maxWidth={700}>
      It is all about simplicity. We focus our emotional message on lightness, freedom, and ease. We
      creatively play with space, objects, story, motion principles, limitations, and harmonization.
    </Text>

    <Divider mt={72} mb={64} />

    <Title size="h4" mb="l">
      In motion
    </Title>
    <Flex mb="xl">
      <Box mr="xxl" mt={5}>
        <img src={easing} alt="" height={94} />
      </Box>
      <Text color="grayscale.600" mt="xs" mb={0}>
        div &#123;
        <br /> &nbsp;&nbsp;transition: all 1s cubic-bezier(0.77, 0, 0.175, 1);
        <br />
        &#125;
      </Text>
    </Flex>
    <Text maxWidth={700}>
      Nothing in nature moves in a linear manner, from one point to another. An aesthetically
      pleasing animation is the result of right{' '}
      <Link
        underline={1}
        href="https://developers.google.com/web/fundamentals/design-and-ux/animations/the-basics-of-easing"
      >
        easing
      </Link>
      . We love when animated objects act close to real-life. We tend to perceive what we expect to
      perceive.
    </Text>
    <Text maxWidth={700}>
      You can{' '}
      <Link underline={1} href="https://easings.net/#easeInOutQuart">
        check the exact easing we use
      </Link>{' '}
      and many other variants.
    </Text>

    <Divider mt={72} mb={64} maxWidth={750} />

    <Box position="relative">
      <Title size="h4" mb="m">
        At its heart
      </Title>
    </Box>
    <Text maxWidth={700}>
      Green shape is the main character in our story. It is fast, proactive and ready to adjust. It
      clearly defines the core values and responsibility of our Agency.
    </Text>

    <Divider mt={72} mb={64} maxWidth={750} />

    <Title size="h4" mb="l">
      Spacings
    </Title>
    <Flex mb={72}>
      <Title size="h1" mr={80} as="div">
        8pt
      </Title>
      <Box maxWidth={400}>
        <Text>All spacing we use are the multiple of 8: 8 / 16 / 24 / 32 / 40 / 48 /... 8*n.</Text>
        <Text>
          You can use 4 on a <Text as="strong">very</Text> exceptional basis.
        </Text>
      </Box>
    </Flex>
    <Text maxWidth={700}>
      • The spacings have their logic — two unrelated things must be far away.
      <br />• Layouts need to breathe. <br />• The bigger the object, the bigger the space around
      it.
    </Text>

    <Divider mt={72} mb={64} />

    <Title size="h4" mb="l">
      Colors
    </Title>
    <Flex mb="xl">
      <Box width={366}>
        <Title as="div" size="h5" mb={-2}>
          80%
        </Title>
        <Text mb={5} as="div">
          White
        </Text>
        <Text color="grayscale.600" as="div" fontSize="s" mb={20}>
          {themeGet('colors.white')}
        </Text>
        <ColorBox bg="white" height={46} borderRadius="10px 0 0 10px" />
      </Box>
      <Box width={84} ml={-1}>
        <Title as="div" size="h5" mb={-2}>
          10%
        </Title>
        <Text mb={5}>Brand</Text>
        <Text color="grayscale.600" fontSize="s" mb={20}>
          {themeGet('colors.brand')}
        </Text>
        <Box bg="brand" height={46} />
      </Box>
      <Box width={84}>
        <Title as="div" size="h5" mb={-2}>
          10%
        </Title>
        <Text mb={5}>Black</Text>
        <Text color="grayscale.600" fontSize="s" mb={20}>
          {themeGet('colors.black')}
        </Text>
        <Box bg="black" height={46} borderRadius="0 10px 10px 0" />
      </Box>
      <Box width={84} ml={21}>
        <Title as="div" size="h5" mb={-2}>
          1%
        </Title>
        <Text mb={5}>CTA</Text>
        <Text color="grayscale.600" fontSize="s" mb={20}>
          {themeGet('colors.cta')}
        </Text>
        <Box width={22} bg="cta" height={46} borderRadius="10px" />
      </Box>
    </Flex>
    <Text maxWidth={700}>
      • Overall color proportions{' '}
      <span role="img" aria-label="">
        🔝
      </span>
      <br />• CTA color is different from others
    </Text>

    <Divider mt={72} mb={64} width={750} />

    <Box position="relative">
      <Title size="h4" mb="m">
        Illustrations
      </Title>
    </Box>
    <Text maxWidth={700}>
      • Chaotic at first glance, but precise in details
      <br />• {themeGet('colors.brand')} as background for back <br />• Smaller elements in the
      background are {themeGet('colors.white')} with black 2px border
      <br />• Copy is {themeGet('colors.black')}
    </Text>

    <Divider mt={72} mb={64} width={750} />

    <Title size="h4" mb="m">
      Content
    </Title>
    <Text maxWidth={700}>
      • Use 40-60 symbols in a line of text
      <br />• All font sizes are defined in the guide — you need only follow the guide
      <br />• Provide enough space between paragraphs
    </Text>

    <Divider mt={72} mb={64} />

    <Title size="h4" mb="m">
      Case studies
    </Title>
    <Text maxWidth={700}>
      • Copy and images complement each other
      <br />• We use storytelling wherever possible.
      <br />• Spacings may look chaotic.
      <br />• But that’s not true.
      <br />• Unlimited amount of elements possible.
      <br />• But with limited styles and fonts.
      <br />• It&apos;s all about the little things.
    </Text>

    <Divider my={72} />

    <Box mb="m">
      <img src={avatarDima} alt="" width={68} />
    </Box>
    <Title size="h4" mb="m">
      Pictures
    </Title>
    <Text>
      All avatars and pictures of Heartbeat Crew are black and white with the predominant black
      shade.
    </Text>

    <Divider mt={72} mb={64} />

    <Title size="h4" mb="m">
      Exclusions
    </Title>
    <Text maxWidth={700}>
      We allow ample freedom since this is a creative team we are talking about.
    </Text>
  </Inner>
);

export default Principles;
